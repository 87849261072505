import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Layout from '../components/layout';
import Seo from "../components/seo"
import Hero from '../components/hero';

import OurFeaturedPartners from '../components/our-featured-partners';
import AdditionlaPartners from '../components/additional-partners';

import BlogFeed from '../components/blog-feed';

import patternHex from '../images/backrounds/pattern-hex-white.jpg';
import NeedAQuote from "../components/need-a-quote";


const Index = ({ location }) => (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="Partnerships"
      description="We partner with many trusted companies like Zendesk, GitHub, New Relic, Monday.com and AWS to bring best best in class tools to your project."
    />
    <Hero bgImage={patternHex}>
      <Hero.Inner>
        <Hero.Content>
          <Hero.Title>Play nice with others</Hero.Title>
          <Hero.Description>
            <p className="text-white text-center text-md-left">
              These days every company needs to integrate with other
              technologies. 729 Solutions has extensive experience in getting
              your program to talk to any technology out there.
            </p>
          </Hero.Description>
          <Hero.CallToAction>
            <AnchorLink className="btn btn-secondary-light" to="#our_partners">
              Our partners
            </AnchorLink>
          </Hero.CallToAction>
        </Hero.Content>
      </Hero.Inner>
    </Hero>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>729 Solutions Partnerships</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <p>
              These days every company needs to integrate with other
              technologies. Your product has to play nicely with your customers
              and third-party solutions. Unfortunately, there are innumerable
              platforms, database structures, APIs, SDKs, and stacks. 729
              Solutions offers the deep experience of working with clients over
              15 years to help your program talk to any technology out there. As
              the oldest Agile development company in the Bay Area, we have a
              long and well-vetted track record of excellence.
            </p>
          </div>
          <div className="col-lg-6">
            <p>
              We have worked for Fortune 500 companies and startups alike
              throughout our history and we are known for our high-velocity
              programming as well as having an extremely tight Agile process. We
              teach Agile in San Francisco and help some of the fastest-growing
              companies improve their process. Talk to us today about our
              experience, customers, and capabilities and you will see why we
              are the best shop to partner with.
            </p>
          </div>
        </div>
      </div>
    </section>
    <OurFeaturedPartners />
    <AdditionlaPartners />
    <section>
      <div className="container pb-6">
        <div className="row ">
          <div className="col">
            <h2>integrations solutions</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="accordion-729">
              <div>
                <input
                  className="ac-input"
                  id="ac-1"
                  name="accordion-1"
                  type="checkbox"
                />
                <label className="ac-label" for="ac-1">
                  Integrations For Your Customers<span></span>
                </label>
                <div className="article ac-content">
                  <p className="m-0">
                    Do you have an exciting, fast-growing product that your
                    customers love? Are you constantly being asked to build this
                    little widget, that little piece of code, make a small
                    update, change or a thousand other requests? Soon you become
                    your customer’s development shop and lose the focus on your
                    own roadmap. We see this story over and again. This is where
                    729 Solutions comes to the rescue. We provide the
                    much-needed manpower to execute all the outside integrations
                    so you can sell your product better, partner with more
                    services and tools and give your customers everything they
                    need. Remember: you are the product company. We are the
                    custom development shop!
                  </p>
                </div>
              </div>
              <div>
                <input
                  className="ac-input"
                  id="ac-2"
                  name="accordion-1"
                  type="checkbox"
                />
                <label className="ac-label" for="ac-2">
                  Integrating Your Product With Others<span></span>
                </label>
                <div className="article ac-content">
                  <p className="m-0">
                    So you want to play with that big ugly .Net SDK? Or you’d
                    like to find a way to plug into Slack, Uber or some other
                    hot new tool. But you don’t have the developers or folks who
                    know all the languages needed. 729 can help your core
                    product connect to almost any program. We can mitigate risk
                    by engineering middleware or wrappers that make sure your
                    codebase is both protected and efficiently connected with
                    the data and processing you need.
                  </p>
                </div>
              </div>
              <div>
                <input
                  className="ac-input"
                  id="ac-3"
                  name="accordion-1"
                  type="checkbox"
                />
                <label className="ac-label" for="ac-3">
                  Building APIs<span></span>
                </label>
                <div className="article ac-content">
                  <p className="m-0">
                    We can help you scope, design and build a leading-edge API
                    that quickly and scalably receives and distributes the right
                    data. We can help document your API so internal and external
                    developers can quickly connect to your endpoints and create
                    value and partnership. We architect APIs that protect your
                    core code and scale beautifully.
                  </p>
                </div>
              </div>
              <div>
                <input
                  className="ac-input"
                  id="ac-4"
                  name="accordion-1"
                  type="checkbox"
                />
                <label className="ac-label" for="ac-4">
                  Some Typical Integrations We Work On<span></span>
                </label>
                <div className="article ac-content">
                  <ul className="text-primary m-0 pl-3">
                    <li>
                      <span className="text-dark">
                        Enterprise Legacy Systems .Net, perl, VB6, ASP 3.0
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Distributed &amp; remote data sources
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">Custom Applications</span>
                    </li>
                    <li>
                      <span className="text-dark">Building APIs</span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Building translators to connect all stacks: Php, C++,
                        C#, ruby, Python, and Java
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Algorithmic integrations
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Salesforce, NetSuite, Microsoft, Google, Slack
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Building special tools to monitor, report, measure, and
                        learn
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <input
                  className="ac-input"
                  id="ac-5"
                  name="accordion-1"
                  type="checkbox"
                />
                <label className="ac-label" for="ac-5">
                  How Does Partnership Work?<span></span>
                </label>
                <div className="article ac-content">
                  <p className="m-0">
                    When a customer next asks you to do a development project
                    that you can’t or don’t want to do simply contact us and
                    bring us in as the programming resource. We work
                    hand-in-hand with you and your customer to engineer the best
                    solution. You can even make a markup on our work as we
                    charge a base fee and you can bill your customer directly.
                    729 makes sure the work is done to your satisfaction and
                    aligns with your code and product roadmap. We give the
                    customer our rich expertise and quality code at high
                    velocity.
                  </p>
                </div>
              </div>
              <div>
                <input
                  className="ac-input"
                  id="ac-6"
                  name="accordion-1"
                  type="checkbox"
                />
                <label className="ac-label" for="ac-6">
                  Some Of Our Integrations Customers<span></span>
                </label>
                <div className="article ac-content">
                  <h3>Verizon</h3>
                  <p>
                    Built a scalable portal that pulled information for a
                    variety of news feed services of different types and formats
                    as well as integrating with live video feeds from the NFL.
                  </p>

                  <h3>Suzuki</h3>
                  <p>
                    Built a high-performance integration with a geolocation
                    service.
                  </p>

                  <h3>Oncor</h3>
                  <p>
                    Integrated multiple, disparate, legacy systems for a major
                    utility through a service-oriented architecture to allow a
                    single, public-facing, system to supply electric and gas
                    usage data to consumer, commercial and industrial customers
                    in near real-time.
                  </p>

                  <h3>Associated &amp; Alta</h3>
                  <p>
                    Integrated a custom, scalable, CRM/ERP solution with their
                    legacy, homegrown, inventory management system that lives
                    on-site in their warehouse.
                  </p>

                  <h3>Namecheap</h3>
                  <p>
                    Helped them integrate a poorly designed node.js -&gt; SQL
                    Server wrapper API. Two things that do not work and play
                    well together.
                  </p>

                  <h3>PCA</h3>
                  <p className="m-0">
                    Created a unified interface for a distributed team to help
                    them manage workflow that required synchronization of data
                    between their internal data sources, NetSuite and
                    Salesforce.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <NeedAQuote dark />
    <BlogFeed containerClassname="py-6" />
  </Layout>
);

  export default Index;
